import { defineStore } from 'pinia';
import { CartType, type ICart, type ICommandResult, type IProduct, type IVariant, SubscriptionInterval, type ICartItem, type IOrderItem, DeliveryType  } from '~~/api-types';
import { useGlobalContentStore } from './globalContent';
import { useVoyadoStore } from './voyado';
import { useCheckoutStore } from '~/store/checkout';
import { useErrorStore } from './error';
import * as Sentry from '@sentry/vue';

export const useCartStore = defineStore('cart', {
  state: () => ({
    items: [] as string[],
    standardCart: null as ICart | null,
    subscriptionCart: null as ICart | null,
    lastUpdatedCart: CartType.Standard,
    promotionError: '' as string | undefined,
    onCheckoutPage: false as boolean,
    showCartNoticeVal: false as boolean,
    hasLoadedCart: false as boolean,
  }),
  actions: {
    async initCart() {
      const globalContentStore = useGlobalContentStore();
      const runtimeConfig = useRuntimeConfig();
      const lastUpdatedCart = sessionStorage.getItem('lastUpdatedCart');
      this.lastUpdatedCart = lastUpdatedCart as CartType || CartType.Standard;

      const standardCartRes: ICart = await $fetch(
        `${runtimeConfig.public.apiUrl}cart?countryCode=${globalContentStore.marketSettings.countryCode}&cartType=Standard`
      );

      const subscriptionCartRes: ICart = await $fetch(
        `${runtimeConfig.public.apiUrl}cart?countryCode=${globalContentStore.marketSettings.countryCode}&cartType=Subscription`
      );

      this.standardCart = standardCartRes;
      this.subscriptionCart = subscriptionCartRes;
      this.hasLoadedCart = true;
      Sentry.setTag('basketId', this.standardCart?.id || null);
      Sentry.setTag('subscriptionBasket', this.subscriptionCart?.id || null);
    },
    async addItem({ product, selectedVariant, cartType, selectedQuantity }: { product: IProduct; selectedVariant: IVariant; cartType: CartType, selectedQuantity: number }) {
      const globalContentStore = useGlobalContentStore();
      const { apiPost } = useApiFetch();
      const errorStore = useErrorStore();
      sessionStorage.setItem('lastUpdatedCart', cartType);
      this.lastUpdatedCart = cartType;
      this.showCartNoticeVal = false;
      const sharedResources = globalContentStore.sharedResources;

      if (this.standardCart && this.subscriptionCart) {
        const globalContentStore = useGlobalContentStore();
        const voyadoStore = useVoyadoStore();

        const cartRes = await apiPost<ICommandResult<ICart>>(
          'cart',
          {
            countryCode: globalContentStore.marketSettings.countryCode,
            partNo: selectedVariant.partNo,
            quantity: selectedQuantity,
            priceListId: selectedVariant.price.priceListId,
            cartType,
          }
        );

        if (cartRes.success && cartRes.value) {
          if (cartType === CartType.Subscription) {
            this.subscriptionCart = cartRes.value;
          } else {
            this.standardCart = cartRes.value;
          }

          this.showCartNotice({ product, selectedVariant });

          window.dataLayer?.push({
            event: 'addToCart',
            ecommerce: {
              currencyCode: globalContentStore.marketSettings?.currency?.currencyCode,
              add: {
                products: [{
                  id: product.partNo,
                  brand: product.brand?.name,
                  category: product.category?.name,
                  name: product.name,
                  price: selectedVariant.price.value,
                  variant: product.productType === 'Bundle' ?
                    '5-pack' :
                    selectedVariant?.packageSize + '-pack',
                  quantity: 1,
                }],
              },
            },
          });
          
          voyadoStore.updateCart();

        } else if (cartRes) {
          errorStore.displayBuyButtonError({ message: cartRes.errorMessage, hideCustomerService: true, activeProduct: product.partNo });
          throw new Error();
        } else {
          errorStore.displayBuyButtonError({ message: sharedResources.genericError, activeProduct: product.partNo });
        }
      }
    },
    async showCartNotice({ product, selectedVariant }: { product: IProduct; selectedVariant: IVariant; }) {
      this.showCartNoticeVal = true;
    },
    async updateItem(
      payload: {
        partNo: string,
        lineNo?: number,
        quantity: number,
        cartType: CartType,
        product: ICartItem,
        deliveryType: string,
      }
    ) {
      sessionStorage.setItem('lastUpdatedCart', payload.cartType);
      const errorStore = useErrorStore();
      const checkoutStore = useCheckoutStore();
      const voyadoStore = useVoyadoStore();

      this.lastUpdatedCart = payload.cartType;
      if (this.standardCart && this.subscriptionCart) {
        try {
          const runtimeConfig = useRuntimeConfig();
          const globalContentStore = useGlobalContentStore();

          const cartRes: ICommandResult<ICart> = await $fetch(
            `${runtimeConfig.public.apiUrl}cart`,
            {
              method: 'PUT',
              body: {
                countryCode: globalContentStore.marketSettings.countryCode,
                ...payload,
              },
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': globalContentStore.getAcceptLanguage,
                'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
              },
            }
          );

          if (cartRes.success && cartRes.value) {
            if (payload.cartType === CartType.Subscription) {
              this.subscriptionCart = cartRes.value;
            } else {
              this.standardCart = cartRes.value;
            }

            if (payload.quantity < 1) {
              let name = payload.product.name;

              if (name?.endsWith('-pack')) {
                const index = name?.lastIndexOf(' ');
                name = name?.substring(0, index);
              }

              window.dataLayer?.push({
                event: 'removeFromCart',
                ecommerce: {
                  currencyCode: globalContentStore.marketSettings?.currency?.currencyCode,
                  remove: {
                    products: [{
                      id: payload.product.id,
                      brand: payload.product.brand,
                      category: payload.product.category,
                      name: name,
                      price: payload.product.unitPrice,
                      variant: payload.product?.packageSize + '-pack',
                      quantity: payload.product?.quantity,
                    }],
                  },
                },
              });
            }

            window.dataLayer?.push({
              event: 'Cart',
              action: 'changequantity',
            });

            voyadoStore.updateCart();

            if (this.onCheckoutPage && cartRes.value.items.length > 0) {
              await checkoutStore.updateKCO(payload.cartType);
              await checkoutStore.updateIngrid(payload.cartType, payload.deliveryType, true);
            }
          } else {
            errorStore.displayError({ message: cartRes.errorMessage });
          }
        } catch (e: any) {
          errorStore.displayError({ status: e?.response?.status, message: e?.response?.message });
        }
      }
    },
    async updateInterval(interval: SubscriptionInterval) {
      const errorStore = useErrorStore();

      if (this.standardCart && this.subscriptionCart) {
        try {
          const runtimeConfig = useRuntimeConfig();
          const globalContentStore = useGlobalContentStore();

          const cartRes: ICommandResult<ICart> = await $fetch(
            `${runtimeConfig.public.apiUrl}cart/interval`,
            {
              method: 'PUT',
              body: {
                countryCode: globalContentStore.marketSettings.countryCode,
                subscriptionInterval: interval,
              },
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Accept-Language': globalContentStore.getAcceptLanguage,
                'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
              },
            }
          );

          if (cartRes.success && cartRes.value) {
            this.subscriptionCart = cartRes.value;
          } else {
            errorStore.displayError({ message: cartRes.errorMessage });
          }
        } catch (e: any) {
          errorStore.displayError({ status: e?.response?.status, message: e?.response?.message });
        }
      }
    },
    async applyPromoCode({ code, cartType }: { code: string; cartType: CartType }) {
      const checkoutStore = useCheckoutStore();
      const errorStore = useErrorStore();
      this.promotionError = '';

      try {
        const runtimeConfig = useRuntimeConfig();
        const globalContentStore = useGlobalContentStore();

        const cartRes: ICommandResult<ICart> = await $fetch(
          `${runtimeConfig.public.apiUrl}checkout/promotions`,
          {
            method: 'PUT',
            body: {
              countryCode: globalContentStore.marketSettings.countryCode,
              promoCode: code,
              cartType: cartType,
            },
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Accept-Language': globalContentStore.getAcceptLanguage,
              'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
            },
          }
        );

        if (cartRes.success && cartRes.value) {
          if (cartType === CartType.Subscription) {
            this.subscriptionCart = cartRes.value;
          } else {
            this.standardCart = cartRes.value;
          }
        } else if (!cartRes.success) {
          this.promotionError = cartRes.errorMessage;
          // errorStore.displayError({ message: cartRes.errorMessage });
        }
      } catch (e: any) {
        errorStore.displayError({ status: e?.response?.status, message: e?.response?.message });
      } finally {
        if (this.onCheckoutPage) {
          await checkoutStore.updateIngrid(cartType, undefined, true);
          await checkoutStore.updateKCO(cartType);
        }
      }
    },
    setOnCheckoutPage(value: boolean) {
      this.onCheckoutPage = value;
    },
    async repurchaseOrder(orderItems: IOrderItem[], cartType: CartType) {
      const errorStore = useErrorStore();
      const voyadoStore = useVoyadoStore();
      const globalContentStore = useGlobalContentStore();
      const { apiPost } = useApiFetch();
      const sharedResources = globalContentStore.sharedResources;
      
      const cartRes = await apiPost<ICommandResult<ICart>>(
        'cart/shopagain',
        {
          countryCode: globalContentStore.marketSettings.countryCode,
          items: orderItems,
          cartType,
        }
      );

      if (cartRes && cartRes.success && cartRes.value) {
        this.standardCart = cartRes.value;
        voyadoStore.updateCart();

        window.dataLayer?.push({
          event: 'addToCart',
          ecommerce: {
            currencyCode: globalContentStore.marketSettings?.currency?.currencyCode,
            add: {
              products: orderItems.map((item) => {
                let name = item.name;
                if (name?.endsWith('-pack')) {
                  const index = name?.lastIndexOf(' ');
                  name = name?.substring(0, index);
                }
                return {
                  id: item.id,
                  brand: item.brand,
                  category: item.category,
                  name: name,
                  price: item.unitPrice,
                  variant: item.packageSize,
                  quantity: item.quantity,
                };
              }),
            },
          },
        });
      } else if (cartRes) {
        errorStore.displayError({ message: cartRes.errorMessage });
      } else {
        errorStore.displayError({ message: sharedResources.genericError });
      }
    },
    async repurchaseOrderFromOrderNo(orderNumber: string, cartType: CartType): Promise<boolean | ErrorEvent> {
      const errorStore = useErrorStore();
      const voyadoStore = useVoyadoStore();
      const globalContentStore = useGlobalContentStore();
      const { apiPost } = useApiFetch();
      const sharedResources = globalContentStore.sharedResources;
      const orderNumberDigits = orderNumber.replace(/\D/g,'');

      const cartRes = await apiPost<ICommandResult<ICart>>(
        'cart/shoporderagain',
        {
          countryCode: globalContentStore.marketSettings.countryCode,
          cartId: orderNumberDigits,
          cartType,
        }
      );

      if (cartRes && cartRes.success && cartRes.value) {
        this.standardCart = cartRes.value;
        
        voyadoStore.updateCart();
        return true;
      } else if (cartRes) {
        errorStore.displayError({ message: cartRes.errorMessage });
        return true;
      } else {
        errorStore.displayError({ message: sharedResources.genericError });
        return false;
      }
    },
  }, 
  getters: {
    cartItems(state) {
      if (state.lastUpdatedCart === CartType.Subscription) {
        return state.subscriptionCart?.items;
      } else {
        return state.standardCart?.items;
      }
    },
    itemsLength(state) {
      if (state.lastUpdatedCart === CartType.Subscription) {
        return state.subscriptionCart?.items.reduce((acc, curr) => curr.quantity + acc, 0);
      } else {
        return state.standardCart?.items.reduce((acc, curr) => curr.quantity + acc, 0);
      }
    },
  },
});
