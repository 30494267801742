import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_iGLjBbor9q from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import epiEdit_e8LmJyruGI from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/epiEdit.ts";
import sentry_client_shVUlIjFLk from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/sentry.client.ts";
import updateOnContentSaved_m17u9D0XBg from "/opt/atlassian/pipelines/agent/build/nuxt-app/plugins/updateOnContentSaved.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  plugin_iGLjBbor9q,
  chunk_reload_client_UciE0i6zes,
  epiEdit_e8LmJyruGI,
  sentry_client_shVUlIjFLk,
  updateOnContentSaved_m17u9D0XBg
]