import { defineStore } from 'pinia';
import { useGlobalContentStore } from './globalContent';
import { CartType, type ICommandResult, type IIngridSnippetResult } from '~~/api-types';
const { apiGet, apiPut } = useApiFetch();

export const useCheckoutStore = defineStore('checkout', {
  state: () => ({
    postalCode: '' as string,
    kcoIsInjected: false as boolean,
    isInitialPostalCodeCheck: true,
    onCheckoutPage: false,
    canDeliverToSameStore: false,
  }),
  actions: {
    kcoInjected() {
      this.kcoIsInjected = true;

      return this.kcoIsInjected;
    },
    async suspendKco() {
      const win = window as any;
      if (win._klarnaCheckout && this.kcoIsInjected) {
        win._klarnaCheckout(function(api: { suspend: () => void }) {
          api.suspend();
        });
      }
    },
    async resumeKco() {
      const win = window as any;
      if (win._klarnaCheckout && this.kcoIsInjected) {
        win._klarnaCheckout(function(api: { resume: () => void }) {
          api.resume();
        });
      }
    },
    async setDeliveryMetadata(externalMethodId: string, deliveryType: string, cartType: CartType) {
      if (window._sw) {
        window._sw((api: any) => api.suspend());
      }
      const globalContentStore = useGlobalContentStore();
      const query = `checkout/deliverymetadata?countryCode=${globalContentStore.marketSettings.countryCode}&CartType=${cartType}`;
      await apiPut(query, {
        externalMethodId,
        deliveryType,
        cartType,
        countryCode: globalContentStore.marketSettings.countryCode,
      });
      if (window._sw) {
        window._sw((api: any) => api.resume());
      }
    },
    async updateKCO(cartType: CartType) {
      await this.suspendKco();
      const globalContentStore = useGlobalContentStore();
      const query = `checkout/klarnacheckout/form?countryCode=${globalContentStore.marketSettings.countryCode}&CartType=${cartType}`;
      await apiGet(query);
      await this.resumeKco();
    },
    async updateIngrid(cartType: CartType, deliveryType = 'Standard', updateSession = false) {
      if (window._sw) {
        window._sw((api: any) => api.suspend());
      }
      const globalContentStore = useGlobalContentStore();

      const query = `checkout/ingrid/snippet?countryCode=${globalContentStore.marketSettings.countryCode}&CartType=${cartType}&DeliveryType=${deliveryType}&UpdateSession=${updateSession}`;
      const res = await apiGet<ICommandResult<IIngridSnippetResult>>(query);

      if (res && res.success) {
        this.canDeliverToSameStore = res.value?.canDeliverToStore || false;
      }
      if (window._sw) {
        window._sw((api: any) => api.resume());
      }
    },
    setPostalCode(postalCode: string) {
      this.postalCode = postalCode;
    },
  },
});
