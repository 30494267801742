import { default as _91_46_46_46path_93MMe3seiMRhMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/[...path].vue?macro=true";
import { default as AccessDeniedc7BajI8tsmMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/AccessDenied.vue?macro=true";
import { default as ClientErrorTestBkI0fT7UJGMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ClientErrorTest.vue?macro=true";
import { default as ServerErrorTestAF9WI12wUKMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ServerErrorTest.vue?macro=true";
export default [
  {
    name: _91_46_46_46path_93MMe3seiMRhMeta?.name ?? "path",
    path: _91_46_46_46path_93MMe3seiMRhMeta?.path ?? "/:path(.*)*",
    meta: _91_46_46_46path_93MMe3seiMRhMeta || {},
    alias: _91_46_46_46path_93MMe3seiMRhMeta?.alias || [],
    redirect: _91_46_46_46path_93MMe3seiMRhMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: AccessDeniedc7BajI8tsmMeta?.name ?? "AccessDenied",
    path: AccessDeniedc7BajI8tsmMeta?.path ?? "/AccessDenied",
    meta: AccessDeniedc7BajI8tsmMeta || {},
    alias: AccessDeniedc7BajI8tsmMeta?.alias || [],
    redirect: AccessDeniedc7BajI8tsmMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/AccessDenied.vue").then(m => m.default || m)
  },
  {
    name: ClientErrorTestBkI0fT7UJGMeta?.name ?? "ClientErrorTest",
    path: ClientErrorTestBkI0fT7UJGMeta?.path ?? "/ClientErrorTest",
    meta: ClientErrorTestBkI0fT7UJGMeta || {},
    alias: ClientErrorTestBkI0fT7UJGMeta?.alias || [],
    redirect: ClientErrorTestBkI0fT7UJGMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ClientErrorTest.vue").then(m => m.default || m)
  },
  {
    name: ServerErrorTestAF9WI12wUKMeta?.name ?? "ServerErrorTest",
    path: ServerErrorTestAF9WI12wUKMeta?.path ?? "/ServerErrorTest",
    meta: ServerErrorTestAF9WI12wUKMeta || {},
    alias: ServerErrorTestAF9WI12wUKMeta?.alias || [],
    redirect: ServerErrorTestAF9WI12wUKMeta?.redirect,
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ServerErrorTest.vue").then(m => m.default || m)
  }
]